import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar.js';
import ListOfGames from './pages/listofgames.js';
import Landing from './pages/landing.js';
import SignIn from './components/SignIn.js'
import Register from './components/register.js'
import SignOutSuccess from './components/signoutsuccess.js'
import PasswordResetRequest from './components/passwordresetrequest.js'
import PasswordResetConfirm from './components/passwordresetconfirmation.js'
import { useAppState } from './AppStateContext.js';
import Games from './components/games.js';

const App = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { isAuthenticated } = useAppState();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      console.log('Token being sent:', token);
    }
    else {
      console.log('No token found');
    }
  }, []);

  return (
    <Router>
      <div className="App" id="outer-container">
        <Sidebar 
            selectedDate={selectedDate} 
            setSelectedDate={setSelectedDate}
          />
        <div id="page-wrap">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route 
            path="/signin" 
            element={
              isAuthenticated ? 
                <Navigate to="/" /> : 
                <SignIn/>
            } 
          />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/nfl" 
            element={
              <ListOfGames 
                league="NFL" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/signout"
            element={<SignOutSuccess/>}
          />
          <Route
            path="/passwordresetrequest"
            element={<PasswordResetRequest />}
          />
          <Route
            path="/passwordresetconfirm/:uid/:token"
            element={<PasswordResetConfirm />}
          />
          <Route
            path="/fixture/:id"
            element={<Games isAuthenticated={isAuthenticated} />}
          />
          <Route 
            path="/ncaaf" 
            element={
              <ListOfGames 
                league="NCAAF" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/nba" 
            element={
              <ListOfGames 
                league="NBA" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/epl" 
            element={
              <ListOfGames 
                league="EPL" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/nhl" 
            element={
              <ListOfGames 
                league="NHL" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/nrl" 
            element={
              <ListOfGames 
                league="NRL" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/mls" 
            element={
              <ListOfGames 
                league="MLS" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />          
          <Route 
            path="/mlb" 
            element={
              <ListOfGames 
                league="MLB" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/afl" 
            element={
              <ListOfGames 
                league="AFL" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />
          <Route 
            path="/ncaa" 
            element={
              <ListOfGames 
                league="NCAA" 
                selectedDate={selectedDate} 
                isAuthenticated={isAuthenticated}
              />
            }
            />      
        </Routes>
        </div>
      </div>

    </Router>
  )
}

export default App;

import React, { useState } from 'react';
import { FormGroup, Label, FormText } from 'reactstrap';
import { DatePicker } from 'reactstrap-date-picker'
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { useAppState } from '../AppStateContext'

import '../styles/sidebar.css';

const Sidebar = ({ selectedDate, setSelectedDate }) => {
  const [value, setValue] = useState(new Date().toISOString());
  const [fmtValue, setFmtValue] = useState(new Date().toISOString());
  const { isAuthenticated, isTutorialMode, toggleTutorialMode } = useAppState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChange = (value, formattedValue) => {
    setValue(value);
    setFmtValue(formattedValue);
    setSelectedDate(new Date(value));
  };

  // Handle menu state changes
  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  // Close menu function
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Menu 
      right
      isOpen={isMenuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <hr />
      <Link id="home" className="menu-item" to="/" onClick={closeMenu}>
        Home
      </Link>
      <hr />
      {!isAuthenticated ? (
        <>
          <Link id="signin" className="menu-item" to="/signin" onClick={closeMenu}>
            Sign In
          </Link>
          <hr />
          <Link id="register" className="menu-item" to="/register" onClick={closeMenu}>
            Register
          </Link>
        </>
      ) : (
        <Link id="signout" className="menu-item" to="/signout" onClick={closeMenu}>
          Sign Out
        </Link>
      )}
      <hr />
      <b>American Football</b>
      <Link id="NFL" className="menu-item" to="/NFL" onClick={closeMenu}>
        NFL
      </Link>
      <Link id="NCAAF" className="menu-item" to="/NCAAF" onClick={closeMenu}>
        NCAAF
      </Link>
      <hr />
      <b>Basketball</b>
      <Link id="NBA" className="menu-item" to="/NBA" onClick={closeMenu}>
        NBA
      </Link>
      <Link id="NCAA" className="menu-item" to="/NCAA" onClick={closeMenu}>
        NCAA
      </Link>
      <hr />
      {/* <b>Soccer</b>
        <Link id="EPL" className="menu-item" to="/EPL" onClick={closeMenu}>
          EPL
        </Link>
        <Link id="MLS" className="menu-item" to="/MLS" onClick={closeMenu}>
          MLS
        </Link>
        <hr /> */}
      <Link id="NHL" className="menu-item" to="/NHL" onClick={closeMenu}>
        NHL
      </Link>
      <Link id="MLB" className="menu-item" to="/MLB" onClick={closeMenu}>
        MLB
      </Link>
      <Link id="NRL" className="menu-item" to="/NRL" onClick={closeMenu}>
        NRL
      </Link>
      <Link id="AFL" className="menu-item" to="/AFL" onClick={closeMenu}>
        AFL
      </Link>

      <hr />
      <FormGroup check>
        <Label check>
          <input
            type="checkbox"
            checked={isTutorialMode}
            onChange={toggleTutorialMode}
          />{" "}
          Tutorial Mode
        </Label>
      </FormGroup>
      <FormGroup>
        <Label>Select Date</Label>
        <DatePicker
          id="example-datepicker"
          value={value}
          onChange={(v, f) => handleChange(v, f)}
          style={{ fontSize: "16px" }}
        />
      </FormGroup>
    </Menu>
  );
};
  
export default Sidebar;
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../AppStateContext';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Alert
} from 'reactstrap';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const { login } = useAppState();
  const navigate = useNavigate();

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentStep < 4) {
        nextStep();
      }
    }
  };

  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep !== 4) {
      return;
    }
    if (password1 !== password2) {
      setError("Passwords don't match");
      return;
    }
    try {
      const url = process.env.REACT_APP_API_URL;
      await axios.post(`${url}/auth/registration/`, {
        username,
        email,
        password1,
        password2
      });
      const loginResponse = await axios.post(`${url}/auth/login/`, {
        username,
        password: password1
      });
      login(loginResponse.data.token);
      navigate('/');
    } catch (error) {
      if (error.response?.data?.non_field_errors) {
        setError('Invalid registration details');
      } else if (!error.response) {
        setError('Network error. Please check your connection');
      } else if (error.response?.data) {
        const firstError = Object.values(error.response.data)[0];
        setError(Array.isArray(firstError) ? firstError[0] : firstError);
      } else {
        setError('An error occurred. Please try again later');
      }
    }
  };


  return (
    <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', backgroundColor: 'black', color: 'white' }}>
      <Row className="w-100 justify-content-center">
        <Col xs="11" sm="10" md="8" lg="6" xl="4">
          <Card className="bg-dark text-white">
            <CardHeader className="bg-black text-white text-center py-3">
              <h2 className="h3 mb-0" style={{ fontSize: '1.5rem' }}>Register - Step {currentStep} of 4</h2>
            </CardHeader>
            <CardBody>
              {error && <Alert color="danger" className="text-center">{error}</Alert>}
            <Form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
                {currentStep === 1 && (
                  <FormGroup>
                    <Label for="username" className="mb-2" style={{ fontSize: '1.1rem' }}>Username</Label>
                    <Input
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                      className="bg-dark text-white mb-3 py-2"
                      style={{ fontSize: '16px', height: 'auto' }}
                    />
                  </FormGroup>
                )}

                {currentStep === 2 && (
                  <FormGroup>
                    <Label for="email" className="mb-2" style={{ fontSize: '1.1rem' }}>Email</Label>
                    <Input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="bg-dark text-white mb-3 py-2"
                      style={{ fontSize: '16px', height: 'auto' }}
                    />
                  </FormGroup>
                )}

                {currentStep === 3 && (
                  <FormGroup>
                    <Label for="password1" className="mb-2" style={{ fontSize: '1.1rem' }}>Password</Label>
                    <Input
                      type="password"
                      id="password1"
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      required
                      className="bg-dark text-white mb-3 py-2"
                      style={{ fontSize: '16px', height: 'auto' }}
                    />
                  </FormGroup>
                )}

                {currentStep === 4 && (
                  <FormGroup>
                    <Label for="password2" className="mb-2" style={{ fontSize: '1.1rem' }}>Confirm Password</Label>
                    <Input
                      type="password"
                      id="password2"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                      className="bg-dark text-white mb-3 py-2"
                      style={{ fontSize: '16px', height: 'auto' }}
                    />
                  </FormGroup>
                )}

              <div className="d-flex justify-content-between mt-4">
                <Button 
                  type="button" 
                  color="secondary" 
                  onClick={prevStep} 
                  disabled={currentStep === 1}
                  className="py-2" 
                  style={{ fontSize: '1.1rem', width: '45%' }}
                >
                  Back
                </Button>
                
                {currentStep < 4 ? (
                  <Button 
                    type="button" 
                    color="primary" 
                    onClick={nextStep}
                    className="py-2" 
                    style={{ fontSize: '1.1rem', width: '45%' }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button 
                    type="submit"
                    color="primary" 
                    className="py-2" 
                    style={{ fontSize: '1.1rem', width: '45%' }}
                  >
                    Register
                  </Button>
                )}
              </div>
            </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { StarRating } from "../../components/starrating.js";
import { useAppState } from "../../AppStateContext.js";

function generateAnalysis(fixture) {
  const insights = [];
  
  const calculateWinPercentage = (wins, draws, total) => {
    return ((wins + draws * 0.5) / total) * 100;
  };


  // Calculate key percentages
  const home3Games = calculateWinPercentage(fixture.recent.home_win_3, fixture.recent.home_draws_3, 3);
  const away3Games = calculateWinPercentage(fixture.recent.away_win_3, fixture.recent.away_draws_3, 3);
  const home5Games = calculateWinPercentage(fixture.recent.home_win_5, fixture.recent.home_draws_5, 5);
  const away5Games = calculateWinPercentage(fixture.recent.away_win_5, fixture.recent.away_draws_5, 5);
  const home10Games = calculateWinPercentage(fixture.recent.home_win_10, fixture.recent.home_draws_10, 10);
  const away10Games = calculateWinPercentage(fixture.recent.away_win_10, fixture.recent.away_draws_10, 10);
  const homeSeason = calculateWinPercentage(fixture.stats.home_wins, fixture.stats.home_draws, fixture.stats.count_home);
  const awaySeason = calculateWinPercentage(fixture.stats.away_wins, fixture.stats.away_draws, fixture.stats.count_away);
  const homeHomeGames = calculateWinPercentage(fixture.stats.home_home_wins, fixture.stats.home_home_draws, 
    fixture.stats.home_home_wins + fixture.stats.home_home_losses + fixture.stats.home_home_draws);
  const awayAwayGames = calculateWinPercentage(fixture.stats.away_away_wins, fixture.stats.away_away_draws,
    fixture.stats.away_away_wins + fixture.stats.away_away_losses + fixture.stats.away_away_draws);

  // Recent form analysis - last 3 games
  const recent3Diff = home3Games - away3Games;
  if (Math.abs(recent3Diff) > 30) {
    insights.push(`${recent3Diff > 0 ? fixture.home_team : fixture.away_team} have been performing significantly better in their last 3 games`);
  } else if (Math.abs(recent3Diff) > 20) {
    insights.push(`${recent3Diff > 0 ? fixture.home_team : fixture.away_team} have been performing better in their last 3 games`);
  } else if (Math.abs(recent3Diff) > 10) {
    insights.push(`${recent3Diff > 0 ? fixture.home_team : fixture.away_team} have been performing somewhat better in their last 3 games`);
  }

  // Recent form analysis - last 5 games
  const recent5Diff = home5Games - away5Games;
  if (Math.abs(recent5Diff) > 30) {
    insights.push(`${recent5Diff > 0 ? fixture.home_team : fixture.away_team} have been performing significantly better in their last 5 games`);
  } else if (Math.abs(recent5Diff) > 20) {
    insights.push(`${recent5Diff > 0 ? fixture.home_team : fixture.away_team} have been performing better in their last 5 games`);
  } else if (Math.abs(recent5Diff) > 10) {
    insights.push(`${recent5Diff > 0 ? fixture.home_team : fixture.away_team} have been performing somewhat better in their last 5 games`);
  }

  // Recent form analysis - last 10 games
  const recent10Diff = home10Games - away10Games;
  if (Math.abs(recent10Diff) > 30) {
    insights.push(`${recent10Diff > 0 ? fixture.home_team : fixture.away_team} have been performing significantly better over their last 10 games`);
  } else if (Math.abs(recent10Diff) > 20) {
    insights.push(`${recent10Diff > 0 ? fixture.home_team : fixture.away_team} have been performing better over their last 10 games`);
  } else if (Math.abs(recent10Diff) > 10) {
    insights.push(`${recent10Diff > 0 ? fixture.home_team : fixture.away_team} have been performing somewhat better over their last 10 games`);
  }

  // Season performance analysis
  const seasonDiff = homeSeason - awaySeason;
  if (Math.abs(seasonDiff) > 30) {
    insights.push(`${seasonDiff > 0 ? fixture.home_team : fixture.away_team} have had a significantly better season overall`);
  } else if (Math.abs(seasonDiff) > 20) {
    insights.push(`${seasonDiff > 0 ? fixture.home_team : fixture.away_team} have had a better season overall`);
  } else if (Math.abs(seasonDiff) > 10) {
    insights.push(`${seasonDiff > 0 ? fixture.home_team : fixture.away_team} have had a somewhat better season overall`);
  }

  // Home/Away analysis
  const homeAwayDiff = homeHomeGames - awayAwayGames;
  if (Math.abs(homeAwayDiff) > 30) {
    insights.push(`${homeAwayDiff > 0 ? fixture.home_team : fixture.away_team} have been significantly stronger in their home/away games`);
  } else if (Math.abs(homeAwayDiff) > 20) {
    insights.push(`${homeAwayDiff > 0 ? fixture.home_team : fixture.away_team} have been stronger in their home/away games`);
  } else if (Math.abs(homeAwayDiff) > 10) {
    insights.push(`${homeAwayDiff > 0 ? fixture.home_team : fixture.away_team} have been somewhat stronger in their home/away games than ${homeAwayDiff > 0 ? fixture.away_team : fixture.home_team}`);
  }

  return insights;
}

const Wins = ({ fixture }) => {
  const [displayMode, setDisplayMode] = useState(2); // 0: Win-Loss, 1: Percentages, 2: Stars (default)
  const { isTutorialMode } = useAppState();

  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  const calculateWinPercentage = (wins, draws, total) => {
    return ((wins + draws * 0.5) / total) * 100;
  };

  const calculateStars = (ev1, ev2) => {
    if (isNaN(ev1) || isNaN(ev2)) return 0;
    const difference = ev1 - ev2;
    if (difference <= 0) return 0;
    if (difference > 0 && difference <= 10) return 1;
    if (difference > 10 && difference <= 30) return 2;
    return 3;
  };

  if (!fixture.recent) return null;

  const insights = generateAnalysis(fixture);

  return (
    <>
    <Table bordered className="main">
      <tbody>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.Cells}>Wins</td>
          <td className="Cells" style={styles.Cells}></td>
          <td className="Cells" style={styles.Cells}></td>
        </tr>
        <tr>
          <td className="Cells" style={styles.Cells}></td>
          <td className="Cells" style={styles.column_1}>{fixture.home_team}</td>
          <td className="Cells" style={styles.column_1}>{fixture.away_team}</td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Last 3</td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.recent.home_win_3} - ${fixture.recent.home_loss_3}${isSupportedSport(fixture) ? ` - ${fixture.recent.home_draws_3}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.recent.home_win_3, fixture.recent.home_draws_3, 3).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.recent.home_win_3, fixture.recent.home_draws_3, 3),
                  calculateWinPercentage(fixture.recent.away_win_3, fixture.recent.away_draws_3, 3)
                )} />
            }
          </td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.recent.away_win_3} - ${fixture.recent.away_loss_3}${isSupportedSport(fixture) ? ` - ${fixture.recent.away_draws_3}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.recent.away_win_3, fixture.recent.away_draws_3, 3).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.recent.away_win_3, fixture.recent.away_draws_3, 3),
                  calculateWinPercentage(fixture.recent.home_win_3, fixture.recent.home_draws_3, 3)
                )} />
            }
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Last 5</td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.recent.home_win_5} - ${fixture.recent.home_loss_5}${isSupportedSport(fixture) ? ` - ${fixture.recent.home_draws_5}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.recent.home_win_5, fixture.recent.home_draws_5, 5).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.recent.home_win_5, fixture.recent.home_draws_5, 5),
                  calculateWinPercentage(fixture.recent.away_win_5, fixture.recent.away_draws_5, 5)
                )} />
            }
          </td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.recent.away_win_5} - ${fixture.recent.away_loss_5}${isSupportedSport(fixture) ? ` - ${fixture.recent.away_draws_5}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.recent.away_win_5, fixture.recent.away_draws_5, 5).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.recent.away_win_5, fixture.recent.away_draws_5, 5),
                  calculateWinPercentage(fixture.recent.home_win_5, fixture.recent.home_draws_5, 5)
                )} />
            }
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Last 10</td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.recent.home_win_10} - ${fixture.recent.home_loss_10}${isSupportedSport(fixture) ? ` - ${fixture.recent.home_draws_10}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.recent.home_win_10, fixture.recent.home_draws_10, 10).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.recent.home_win_10, fixture.recent.home_draws_10, 10),
                  calculateWinPercentage(fixture.recent.away_win_10, fixture.recent.away_draws_10, 10)
                )} />
            }
          </td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.recent.away_win_10} - ${fixture.recent.away_loss_10}${isSupportedSport(fixture) ? ` - ${fixture.recent.away_draws_10}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.recent.away_win_10, fixture.recent.away_draws_10, 10).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.recent.away_win_10, fixture.recent.away_draws_10, 10),
                  calculateWinPercentage(fixture.recent.home_win_10, fixture.recent.home_draws_10, 10)
                )} />
            }
          </td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Season</td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.stats.home_wins} - ${fixture.stats.home_losses}${isSupportedSport(fixture) ? ` - ${fixture.stats.home_draws}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.stats.home_wins, fixture.stats.home_draws, fixture.stats.count_home).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.stats.home_wins, fixture.stats.home_draws, fixture.stats.count_home),
                  calculateWinPercentage(fixture.stats.away_wins, fixture.stats.away_draws, fixture.stats.count_away)
                )} />
            }
          </td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.stats.away_wins} - ${fixture.stats.away_losses}${isSupportedSport(fixture) ? ` - ${fixture.stats.away_draws}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.stats.away_wins, fixture.stats.away_draws, fixture.stats.count_away).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.stats.away_wins, fixture.stats.away_draws, fixture.stats.count_away),
                  calculateWinPercentage(fixture.stats.home_wins, fixture.stats.home_draws, fixture.stats.count_home)
                )} />
            }
          </td>
        </tr>
        {shouldShowLastSeason(fixture) && (
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Last Season</td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.stats.home_wins_last} - ${fixture.stats.home_losses_last}${isSupportedSport(fixture) ? ` - ${fixture.stats.home_draws_last}` : ''}`
                : displayMode === 1
                ? `${calculateWinPercentage(fixture.stats.home_wins_last, fixture.stats.home_draws_last, fixture.stats.count_home_last).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    calculateWinPercentage(fixture.stats.home_wins_last, fixture.stats.home_draws_last, fixture.stats.count_home_last),
                    calculateWinPercentage(fixture.stats.away_wins_last, fixture.stats.away_draws_last, fixture.stats.count_away_last)
                  )} />
              }
            </td>
            <td className="Cells" style={styles.Cells}>
              {displayMode === 0
                ? `${fixture.stats.away_wins_last} - ${fixture.stats.away_losses_last}${isSupportedSport(fixture) ? ` - ${fixture.stats.away_draws_last}` : ''}`
                : displayMode === 1
                ? `${calculateWinPercentage(fixture.stats.away_wins_last, fixture.stats.away_draws_last, fixture.stats.count_away_last).toFixed(1)}%`
                : <StarRating rating={calculateStars(
                    calculateWinPercentage(fixture.stats.away_wins_last, fixture.stats.away_draws_last, fixture.stats.count_away_last),
                    calculateWinPercentage(fixture.stats.home_wins_last, fixture.stats.home_draws_last, fixture.stats.count_home_last)
                  )} />
              }
            </td>
          </tr>
        )}
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Home</td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.stats.home_home_wins} - ${fixture.stats.home_home_losses}${isSupportedSport(fixture) ? ` - ${fixture.stats.home_home_draws}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.stats.home_home_wins, fixture.stats.home_home_draws, fixture.stats.home_home_wins + fixture.stats.home_home_losses + fixture.stats.home_home_draws).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.stats.home_home_wins, fixture.stats.home_home_draws, fixture.stats.home_home_wins + fixture.stats.home_home_losses + fixture.stats.home_home_draws),
                  calculateWinPercentage(fixture.stats.away_away_wins, fixture.stats.away_away_draws, fixture.stats.away_away_wins + fixture.stats.away_away_losses + fixture.stats.away_away_draws)
                )} />
            }
          </td>
          <td className="Cells" style={styles.Cells}></td>
        </tr>
        <tr style={styles.Cells}>
          <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Away</td>
          <td className="Cells" style={styles.Cells}></td>
          <td className="Cells" style={styles.Cells}>
            {displayMode === 0
              ? `${fixture.stats.away_away_wins} - ${fixture.stats.away_away_losses}${isSupportedSport(fixture) ? ` - ${fixture.stats.away_away_draws}` : ''}`
              : displayMode === 1
              ? `${calculateWinPercentage(fixture.stats.away_away_wins, fixture.stats.away_away_draws, fixture.stats.away_away_wins + fixture.stats.away_away_losses + fixture.stats.away_away_draws).toFixed(1)}%`
              : <StarRating rating={calculateStars(
                  calculateWinPercentage(fixture.stats.away_away_wins, fixture.stats.away_away_draws, fixture.stats.away_away_wins + fixture.stats.away_away_losses + fixture.stats.away_away_draws),
                  calculateWinPercentage(fixture.stats.home_home_wins, fixture.stats.home_home_draws, fixture.stats.home_home_wins + fixture.stats.home_home_losses + fixture.stats.home_home_draws)
                )} />
            }
          </td>
        </tr>
        {shouldShowLastSeason(fixture) && (
          <>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Home Last Season</td>
              <td className="Cells" style={styles.Cells}>
                {displayMode === 0
                  ? `${fixture.stats.home_home_wins_last} - ${fixture.stats.home_home_losses_last}${isSupportedSport(fixture) ? ` - ${fixture.stats.home_home_draws_last}` : ''}`
                  : displayMode === 1
                  ? `${calculateWinPercentage(fixture.stats.home_home_wins_last, fixture.stats.home_home_draws_last, fixture.stats.home_home_wins_last + fixture.stats.home_home_losses_last + fixture.stats.home_home_draws_last).toFixed(1)}%`
                  : <StarRating rating={calculateStars(
                      calculateWinPercentage(fixture.stats.home_home_wins_last, fixture.stats.home_home_draws_last, fixture.stats.home_home_wins_last + fixture.stats.home_home_losses_last + fixture.stats.home_home_draws_last),
                      50
                    )} />
                }
              </td>
              <td className="Cells" style={styles.Cells}></td>
            </tr>
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>Away Last Season</td>
              <td className="Cells" style={styles.Cells}></td>
              <td className="Cells" style={styles.Cells}>
                {displayMode === 0
                  ? `${fixture.stats.away_away_wins_last} - ${fixture.stats.away_away_losses_last}${isSupportedSport(fixture) ? ` - ${fixture.stats.away_away_draws_last}` : ''}`
                  : displayMode === 1
                  ? `${calculateWinPercentage(fixture.stats.away_away_wins_last, fixture.stats.away_away_draws_last, fixture.stats.away_away_wins_last + fixture.stats.away_away_losses_last + fixture.stats.away_away_draws_last).toFixed(1)}%`
                  : <StarRating rating={calculateStars(
                      calculateWinPercentage(fixture.stats.away_away_wins_last, fixture.stats.away_away_draws_last, fixture.stats.away_away_wins_last + fixture.stats.away_away_losses_last + fixture.stats.away_away_draws_last),
                      50
                    )} />
                }
              </td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
      <div style={{ height: '10vh' }}></div>

      <div style={{ marginTop: '20px' }}>
        {insights.length > 0 && (
          <ul style={{ 
            listStyleType: 'none', 
            padding: 0,
            margin: 0 
          }}>
            {insights.map((insight, i) => (
              <li key={i} style={styles.analysis}>
                • {insight}
              </li>
            ))}
          </ul>
        )}
      </div>

      {isTutorialMode && (
        <ul style={{ fontSize: 'clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)', marginBottom: '20px', textAlign: 'left', listStyleType: 'none', paddingLeft: '0' }}>
          <li>• Tap anywhere in the first column to toggle between modes</li>
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              <li>◦ Win-Loss mode - show the team's win-loss record</li>
              <li>◦ Percentage mode - show the win percentage</li>
              <li>◦ Star mode - for quick evaluation of team performance</li>
            </ul>
          <li>• Star ratings
            <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              <li>◦ ⭐ – Slight advantage</li>
              <li>◦ ⭐⭐ – Moderate advantage</li>
              <li>◦ ⭐⭐⭐ – Strong advantage</li>
            </ul>
          </li>
          <li>• How to interpret the data</li>
          <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
              <li>◦ Compare recent performance (last 3, 5, 10 games) to season-long performance</li>
              <li>◦ Pay attention to home/away performance differences</li>
          </ul>
        </ul>
      )}
    </>
  );
};

// These functions should be moved to a utility file
function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL" || fixture.league === "NHL") {
    return true;
  }
  return fixture.ratings.progress < 0.15;
}

function isSupportedSport(fixture) {
  const supportedSports = ["Hockey", "Soccer", "RugbyLeague", "AussieRules"];
  return supportedSports.includes(fixture.sport);
}

export default Wins;
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Winner from "./winner.js";
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";
import { StarRating } from "../../components/starrating.js";
import createScrollSnap from "scroll-snap";
import Card from "react-bootstrap/Card";

export function HeadtoHead({ team1, team2, game, onClose }) {
  const [data, setData] = useState([]);
  const [displayMode, setDisplayMode] = useState(2); // 0: Win-Loss, 1: Percentages, 2: Stars
  const [showPointDifference, setShowPointDifference] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(
        container,
        {
          snapDestinationY: "0%",
          timeout: 100,
          duration: 300,
          threshold: 0.2,
          snapStop: false,
          easing: (t) => t * (2 - t),
        },
        () => console.log("Element snapped")
      );

      bind();
      return () => unbind();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = process.env.REACT_APP_API_URL;
        const { data: response } = await axios.get(`${url}/getFixtures/`, {
          params: {
            team1: team1,
            team2: team2,
            date: game.game_time,
          },
        });
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [team1, team2, game]);

  const toggleDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  const calculateWinPercentage = (wins, draws, total) => {
    return ((wins + draws * 0.5) / total) * 100;
  };

  const togglePointDifference = () => {
    setShowPointDifference(!showPointDifference);
  };

  const calculateStars = (ev1, ev2) => {
    if (isNaN(ev1) || isNaN(ev2)) return 0;
    const difference = ev1 - ev2;
    if (difference <= 0) return 0;
    if (difference > 0 && difference <= 10) return 1;
    if (difference > 10 && difference <= 30) return 2;
    return 3;
  };

  // Used to display draws/ties
  function isSupportedSport(fixture) {
    const supportedSports = ["Hockey", "Soccer", "RugbyLeague", "AussieRules"];
    return supportedSports.includes(game.sport);
  }

  function generateWinsAnalysis(game) {
    const insights = [];
    
    // Calculate win percentages for last 3, 5, 10 games
    const home3Games = calculateWinPercentage(game.head_to_head.h2h_home_win_3, game.head_to_head.h2h_draws_3, 3);
    const away3Games = calculateWinPercentage(game.head_to_head.h2h_away_win_3, game.head_to_head.h2h_draws_3, 3);
    const home5Games = calculateWinPercentage(game.head_to_head.h2h_home_win_5, game.head_to_head.h2h_draws_5, 5);
    const away5Games = calculateWinPercentage(game.head_to_head.h2h_away_win_5, game.head_to_head.h2h_draws_5, 5);
    const home10Games = calculateWinPercentage(game.head_to_head.h2h_home_win_10, game.head_to_head.h2h_draws_10, 10);
    const away10Games = calculateWinPercentage(game.head_to_head.h2h_away_win_10, game.head_to_head.h2h_draws_10, 10);
  
    // Analyze each time period
    const recent3Diff = home3Games - away3Games;
    if (Math.abs(recent3Diff) > 30) {
      insights.push(`${recent3Diff > 0 ? game.home_team : game.away_team} has dominated the last 3 meetings`);
    }
  
    const recent5Diff = home5Games - away5Games;
    if (Math.abs(recent5Diff) > 30) {
      insights.push(`${recent5Diff > 0 ? game.home_team : game.away_team} has dominated the last 5 meetings`);
    }
  
    const recent10Diff = home10Games - away10Games;
    if (Math.abs(recent10Diff) > 30) {
      insights.push(`${recent10Diff > 0 ? game.home_team : game.away_team} has dominated the last 10 meetings`);
    }
  
    return insights;
  }
  
  function generateSpreadsAnalysis(game) {
    const insights = [];
  
    // Calculate spread percentages
    const home3Games = (game.head_to_head.h2h_home_spread_3 / 3) * 100;
    const away3Games = (game.head_to_head.h2h_away_spread_3 / 3) * 100;
    const home5Games = (game.head_to_head.h2h_home_spread_5 / 5) * 100;
    const away5Games = (game.head_to_head.h2h_away_spread_5 / 5) * 100;
    const home10Games = (game.head_to_head.h2h_home_spread_10 / 10) * 100;
    const away10Games = (game.head_to_head.h2h_away_spread_10 / 10) * 100;
  
    // Analyze each time period
    const recent3Diff = home3Games - away3Games;
    if (Math.abs(recent3Diff) > 30) {
      insights.push(`${recent3Diff > 0 ? game.home_team : game.away_team} has covered significantly more in the last 3 meetings`);
    }
  
    const recent5Diff = home5Games - away5Games;
    if (Math.abs(recent5Diff) > 30) {
      insights.push(`${recent5Diff > 0 ? game.home_team : game.away_team} has covered significantly more in the last 5 meetings`);
    }
  
    const recent10Diff = home10Games - away10Games;
    if (Math.abs(recent10Diff) > 30) {
      insights.push(`${recent10Diff > 0 ? game.home_team : game.away_team} has covered significantly more in the last 10 meetings`);
    }
  
    return insights;
  }
  
  function generateTotalsAnalysis(game) {
    const insights = [];
  
    // Calculate average totals for different periods
    const avg3 = (game.head_to_head.h2h_home_PF_3 + game.head_to_head.h2h_away_PF_3 + 
                  game.head_to_head.h2h_home_PA_3 + game.head_to_head.h2h_away_PA_3) / 2;
    const avg5 = (game.head_to_head.h2h_home_PF_5 + game.head_to_head.h2h_away_PF_5 + 
                  game.head_to_head.h2h_home_PA_5 + game.head_to_head.h2h_away_PA_5) / 2;
    const avg10 = (game.head_to_head.h2h_home_PF_10 + game.head_to_head.h2h_away_PF_10 + 
                   game.head_to_head.h2h_home_PA_10 + game.head_to_head.h2h_away_PA_10) / 2;
  
    // Compare with current over/under line
    const delta3 = avg3 - game.over;
    const delta5 = avg5 - game.over;
    const delta10 = avg10 - game.over;
  
    if (Math.abs(delta3) > 5) {
      insights.push(`Recent meetings (last 3) have averaged ${Math.abs(delta3).toFixed(1)} points ${delta3 > 0 ? 'over' : 'under'} the current line`);
    }
  
    if (Math.abs(delta5) > 5) {
      insights.push(`Recent meetings (last 5) have averaged ${Math.abs(delta5).toFixed(1)} points ${delta5 > 0 ? 'over' : 'under'} the current line`);
    }
  
    if (Math.abs(delta10) > 5) {
      insights.push(`Historical meetings (last 10) have averaged ${Math.abs(delta10).toFixed(1)} points ${delta10 > 0 ? 'over' : 'under'} the current line`);
    }
  
    return insights;
  }

  const winsAnalysis = generateWinsAnalysis(game);
  const spreadsAnalysis = generateSpreadsAnalysis(game);
  const totalsAnalysis = generateTotalsAnalysis(game);

  return (
    <div
      ref={containerRef}
      style={{
        overflowY: "scroll",
        height: "100vh",
        scrollSnapType: "y mandatory",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}>
        <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
          <Table bordered className="main">
            <tbody>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1}>
                  Wins
                </td>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}></td>
              </tr>
              <tr>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}>
                  {game.home_team}
                </td>
                <td className="Cells" style={styles.column_1}>
                  {game.away_team}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Last 3
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_home_win_3} - ${game.head_to_head.h2h_home_loss_3}${
                      isSupportedSport(game) ? ` - ${game.head_to_head.h2h_draws_3}` : ""
                    }`
                  ) : displayMode === 1 ? (
                    `${calculateWinPercentage(game.head_to_head.h2h_home_win_3, game.head_to_head.h2h_draws_3, 3).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        calculateWinPercentage(game.head_to_head.h2h_home_win_3, game.head_to_head.h2h_draws_3, 3),
                        calculateWinPercentage(game.head_to_head.h2h_away_win_3, game.head_to_head.h2h_draws_3, 3)
                      )}
                    />
                  )}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_away_win_3} - ${game.head_to_head.h2h_away_loss_3}${
                      isSupportedSport(game) ? ` - ${game.head_to_head.h2h_draws_3}` : ""
                    }`
                  ) : displayMode === 1 ? (
                    `${calculateWinPercentage(game.head_to_head.h2h_away_win_3, game.head_to_head.h2h_draws_3, 3).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        calculateWinPercentage(game.head_to_head.h2h_away_win_3, game.head_to_head.h2h_draws_3, 3),
                        calculateWinPercentage(game.head_to_head.h2h_home_win_3, game.head_to_head.h2h_draws_3, 3)
                      )}
                    />
                  )}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Last 5
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_home_win_5} - ${game.head_to_head.h2h_home_loss_5}${
                      isSupportedSport(game) ? ` - ${game.head_to_head.h2h_draws_5}` : ""
                    }`
                  ) : displayMode === 1 ? (
                    `${calculateWinPercentage(game.head_to_head.h2h_home_win_5, game.head_to_head.h2h_draws_5, 5).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        calculateWinPercentage(game.head_to_head.h2h_home_win_5, game.head_to_head.h2h_draws_5, 5),
                        calculateWinPercentage(game.head_to_head.h2h_away_win_5, game.head_to_head.h2h_draws_5, 5)
                      )}
                    />
                  )}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_away_win_5} - ${game.head_to_head.h2h_away_loss_5}${
                      isSupportedSport(game) ? ` - ${game.head_to_head.h2h_draws_5}` : ""
                    }`
                  ) : displayMode === 1 ? (
                    `${calculateWinPercentage(game.head_to_head.h2h_away_win_5, game.head_to_head.h2h_draws_5, 5).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        calculateWinPercentage(game.head_to_head.h2h_away_win_5, game.head_to_head.h2h_draws_5, 5),
                        calculateWinPercentage(game.head_to_head.h2h_home_win_5, game.head_to_head.h2h_draws_5, 5)
                      )}
                    />
                  )}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Last 10
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_home_win_10} - ${game.head_to_head.h2h_home_loss_10}${
                      isSupportedSport(game) ? ` - ${game.head_to_head.h2h_draws_10}` : ""
                    }`
                  ) : displayMode === 1 ? (
                    `${calculateWinPercentage(game.head_to_head.h2h_home_win_10, game.head_to_head.h2h_draws_10, 10).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        calculateWinPercentage(game.head_to_head.h2h_home_win_10, game.head_to_head.h2h_draws_10, 10),
                        calculateWinPercentage(game.head_to_head.h2h_away_win_10, game.head_to_head.h2h_draws_10, 10)
                      )}
                    />
                  )}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_away_win_10} - ${game.head_to_head.h2h_away_loss_10}${
                      isSupportedSport(game) ? ` - ${game.head_to_head.h2h_draws_10}` : ""
                    }`
                  ) : displayMode === 1 ? (
                    `${calculateWinPercentage(game.head_to_head.h2h_away_win_10, game.head_to_head.h2h_draws_10, 10).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        calculateWinPercentage(game.head_to_head.h2h_away_win_10, game.head_to_head.h2h_draws_10, 10),
                        calculateWinPercentage(game.head_to_head.h2h_home_win_10, game.head_to_head.h2h_draws_10, 10)
                      )}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          {winsAnalysis.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              {winsAnalysis.map((insight, i) => (
                <li key={i} style={styles.analysis}>
                  • {insight}
                </li>
              ))}
            </div>
          )}
        </section>

        <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
          <Table bordered className="main">
            <tbody>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1}>
                  Spreads
                </td>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}></td>
              </tr>
              <tr>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}>
                  {game.home_team}
                </td>
                <td className="Cells" style={styles.column_1}>
                  {game.away_team}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Last 3
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_home_spread_3} - ${3 - game.head_to_head.h2h_home_spread_3}`
                  ) : displayMode === 1 ? (
                    `${((game.head_to_head.h2h_home_spread_3 / 3) * 100).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        (game.head_to_head.h2h_home_spread_3 / 3) * 100,
                        (game.head_to_head.h2h_away_spread_3 / 3) * 100
                      )}
                    />
                  )}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_away_spread_3} - ${3 - game.head_to_head.h2h_away_spread_3}`
                  ) : displayMode === 1 ? (
                    `${((game.head_to_head.h2h_away_spread_3 / 3) * 100).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        (game.head_to_head.h2h_away_spread_3 / 3) * 100,
                        (game.head_to_head.h2h_home_spread_3 / 3) * 100
                      )}
                    />
                  )}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Last 5
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_home_spread_5} - ${5 - game.head_to_head.h2h_home_spread_5}`
                  ) : displayMode === 1 ? (
                    `${((game.head_to_head.h2h_home_spread_5 / 5) * 100).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        (game.head_to_head.h2h_home_spread_5 / 5) * 100,
                        (game.head_to_head.h2h_away_spread_5 / 5) * 100
                      )}
                    />
                  )}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_away_spread_5} - ${5 - game.head_to_head.h2h_away_spread_5}`
                  ) : displayMode === 1 ? (
                    `${((game.head_to_head.h2h_away_spread_5 / 5) * 100).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        (game.head_to_head.h2h_away_spread_5 / 5) * 100,
                        (game.head_to_head.h2h_home_spread_5 / 5) * 100
                      )}
                    />
                  )}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1} onClick={toggleDisplayMode}>
                  Last 10
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_home_spread_10} - ${10 - game.head_to_head.h2h_home_spread_10}`
                  ) : displayMode === 1 ? (
                    `${((game.head_to_head.h2h_home_spread_10 / 10) * 100).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        (game.head_to_head.h2h_home_spread_10 / 10) * 100,
                        (game.head_to_head.h2h_away_spread_10 / 10) * 100
                      )}
                    />
                  )}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {displayMode === 0 ? (
                    `${game.head_to_head.h2h_away_spread_10} - ${10 - game.head_to_head.h2h_away_spread_10}`
                  ) : displayMode === 1 ? (
                    `${((game.head_to_head.h2h_away_spread_10 / 10) * 100).toFixed(1)}%`
                  ) : (
                    <StarRating
                      rating={calculateStars(
                        (game.head_to_head.h2h_away_spread_10 / 10) * 100,
                        (game.head_to_head.h2h_home_spread_10 / 10) * 100
                      )}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          {spreadsAnalysis.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              {spreadsAnalysis.map((insight, i) => (
                <li key={i} style={styles.analysis}>
                  • {insight}
                </li>
              ))}
            </div>
          )}
        </section>

        <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
          <Table bordered className="main" onClick={togglePointDifference}>
            <tbody>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}>
                  Point Totals
                </td>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}></td>
                <td className="Cells" style={styles.column_1}></td>
              </tr>

              <tr>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  {game.home_team}{" "}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  {game.away_team}{" "}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  Overs
                </td>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  Total
                </td>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  Delta{" "}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  Last 3
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${(game.head_to_head.h2h_home_PF_3 - game.head_to_head.h2h_home_PA_3).toFixed(1)}`
                    : `${game.head_to_head.h2h_home_PF_3} - ${game.head_to_head.h2h_home_PA_3}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${(game.head_to_head.h2h_away_PF_3 - game.head_to_head.h2h_away_PA_3).toFixed(1)}`
                    : `${game.head_to_head.h2h_away_PF_3} - ${game.head_to_head.h2h_away_PA_3}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${((game.head_to_head.h2h_overs_hit_3 / 3) * 100).toFixed(1)}%`
                    : `${game.head_to_head.h2h_overs_hit_3} - ${3 - game.head_to_head.h2h_overs_hit_3}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {(
                    (game.head_to_head.h2h_home_PF_3 +
                      game.head_to_head.h2h_away_PF_3 +
                      game.head_to_head.h2h_home_PA_3 +
                      game.head_to_head.h2h_away_PA_3) /
                    2
                  ).toFixed(1)}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {(
                    (game.head_to_head.h2h_home_PF_3 +
                      game.head_to_head.h2h_away_PF_3 +
                      game.head_to_head.h2h_home_PA_3 +
                      game.head_to_head.h2h_away_PA_3) /
                      2 -
                    game.over
                  ).toFixed(1)}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  Last 5
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${(game.head_to_head.h2h_home_PF_5 - game.head_to_head.h2h_home_PA_5).toFixed(1)}`
                    : `${game.head_to_head.h2h_home_PF_5} - ${game.head_to_head.h2h_home_PA_5}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${(game.head_to_head.h2h_away_PF_5 - game.head_to_head.h2h_away_PA_5).toFixed(1)}`
                    : `${game.head_to_head.h2h_away_PF_5} - ${game.head_to_head.h2h_away_PA_5}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${((game.head_to_head.h2h_overs_hit_5 / 5) * 100).toFixed(1)}%`
                    : `${game.head_to_head.h2h_overs_hit_5} - ${5 - game.head_to_head.h2h_overs_hit_5}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {(
                    (game.head_to_head.h2h_home_PF_5 +
                      game.head_to_head.h2h_away_PF_5 +
                      game.head_to_head.h2h_home_PA_5 +
                      game.head_to_head.h2h_away_PA_5) /
                    2
                  ).toFixed(1)}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {(
                    (game.head_to_head.h2h_home_PF_5 +
                      game.head_to_head.h2h_away_PF_5 +
                      game.head_to_head.h2h_home_PA_5 +
                      game.head_to_head.h2h_away_PA_5) /
                      2 -
                    game.over
                  ).toFixed(1)}
                </td>
              </tr>
              <tr style={styles.Cells}>
                <td className="Cells" style={styles.Cells}>
                  {" "}
                  Last 10
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${(game.head_to_head.h2h_home_PF_10 - game.head_to_head.h2h_home_PA_10).toFixed(1)}`
                    : `${game.head_to_head.h2h_home_PF_10} - ${game.head_to_head.h2h_home_PA_10}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${(game.head_to_head.h2h_away_PF_10 - game.head_to_head.h2h_away_PA_10).toFixed(1)}`
                    : `${game.head_to_head.h2h_away_PF_10} - ${game.head_to_head.h2h_away_PA_10}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {showPointDifference
                    ? `${((game.head_to_head.h2h_overs_hit_10 / 10) * 100).toFixed(1)}%`
                    : `${game.head_to_head.h2h_overs_hit_10} - ${10 - game.head_to_head.h2h_overs_hit_10}`}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {(
                    (game.head_to_head.h2h_home_PF_10 +
                      game.head_to_head.h2h_away_PF_10 +
                      game.head_to_head.h2h_home_PA_10 +
                      game.head_to_head.h2h_away_PA_10) /
                    2
                  ).toFixed(1)}
                </td>
                <td className="Cells" style={styles.Cells}>
                  {(
                    (game.head_to_head.h2h_home_PF_10 +
                      game.head_to_head.h2h_away_PF_10 +
                      game.head_to_head.h2h_home_PA_10 +
                      game.head_to_head.h2h_away_PA_10) /
                      2 -
                    game.over
                  ).toFixed(1)}
                </td>
              </tr>
            </tbody>
          </Table>
          {totalsAnalysis.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              {totalsAnalysis.map((insight, i) => (
                <li key={i} style={styles.analysis}>
                  • {insight}
                </li>
              ))}
            </div>
          )}
        </section>

        <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
          <h5>
            {team1} vs {team2}
          </h5>
          {data.map((fixture) => (
            <div key={fixture.id}>
              <Winner fixture={fixture} team1={team1} team2={team2} />
            </div>
          ))}
        </section>
        <section style={{ ...styles.sectionStyle, minHeight: "100vh", scrollSnapAlign: "start" }}>
          <Card className="cardbutton" style={styles.cardbutton} onClick={onClose}>
            <Card.Text>Close Head to Head</Card.Text>
          </Card>
        </section>
      </div>
    </div>
  );
}

export default HeadtoHead;

import React, { useState, useEffect, useRef } from "react";
import Recents from "./Cards/recents.js";
import HeadtoHead from "./Cards/headtohead.js";
import Score from "./Cards/score.js";
import Versus from "./Cards/versus.js";
import Totals from "./Cards/totals.js";
import PointDiff from "./Cards/pointdiff.js";
import Overs from "./Cards/overs.js";
import Wins from "./Cards/wins.js";
import Spreads from "./Cards/spreads.js";
import Projections from "./Cards/projections.js";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Comments from "./Cards/comments.js";
import { convertUTCDateToLocalDateTime } from "../utils/localtime.js";
import { styles } from "../styles/styles.js";
import { useAppState } from "../AppStateContext.js";
import { useParams, useLocation } from "react-router-dom";
import createScrollSnap from "scroll-snap";
import { useNavigate } from 'react-router-dom';


const Games = ({ isAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [fixture, setFixture] = useState(location.state?.fixture);

  const [isDataVisible, setDataVisible] = useState(false);
  const [isRecentsVisible, setRecentsVisible] = useState(false);
  const [isHeadtoHeadVisible, setHeadtoHeadVisible] = useState(false);
  const [isPowerRatingsVisible, setPowerRatingsVisible] = useState(false);
  const [isButtonListVisible, setButtonListVisible] = useState(true);

  const { isTutorialMode } = useAppState();

  const toggleDataVisibility = () => {
    setDataVisible(!isDataVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleRecentsVisibility = () => {
    setRecentsVisible(!isRecentsVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const toggleHeadtoHeadVisibility = () => {
    setHeadtoHeadVisible(!isHeadtoHeadVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const togglePowerRatingsVisibility = () => {
    setPowerRatingsVisible(!isPowerRatingsVisible);
    setButtonListVisible(!isButtonListVisible);
  };

  const resetAll = () => {
    setDataVisible(false);
    setRecentsVisible(false);
    setHeadtoHeadVisible(false);
    setPowerRatingsVisible(false);
    setButtonListVisible(true);
  };

  const handleBackToGames = () => {
    const gameDate = new Date(fixture.game_time);
    navigate(`/${fixture.league.toLowerCase()}`, {
      state: { selectedDate: gameDate }
    });
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { bind, unbind } = createScrollSnap(
        container,
        {
          snapDestinationY: "0%",
          timeout: 100,
          duration: 300,
          threshold: 0.2,
          snapStop: false,
          easing: (t) => t * (2 - t),
        },
        () => console.log("Element snapped")
      );

      bind();

      return () => {
        unbind();
      };
    }
  }, []);

  return (
    <div>
      <div>
        <Card className="main" style={styles.main}>
          <Card.Body>
            <Card.Title>
              <b>
                {" "}
                <Versus fixture={fixture} /> at {convertUTCDateToLocalDateTime(fixture.game_time)}
              </b>
            </Card.Title>
            <Table className="main" bordered onClick={resetAll}>
              <tbody>
                <tr>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    Home{" "}
                  </td>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    {fixture.home_team}{" "}
                  </td>
                  <td
                    className="Odds"
                    style={fixture.recent && fixture.recent.home_spread === "True" ? styles.Suggested : styles.Odds}
                  >
                    {" "}
                    {fixture.home_spread} ({fixture.home_spread_price})
                  </td>
                  <td
                    className="Odds"
                    style={fixture.recent && fixture.recent.home_ml === "True" ? styles.Suggested : styles.Odds}
                  >
                    {" "}
                    {fixture.home_moneyline}
                  </td>
                  <td
                    className="Odds"
                    style={fixture.recent && fixture.recent.over === "True" ? styles.Suggested : styles.Odds}
                  >
                    {" "}
                    {fixture.over} ({fixture.over_price}){" "}
                  </td>
                </tr>
                <tr>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    Away{" "}
                  </td>
                  <td className="Odds" style={styles.Odds}>
                    {" "}
                    {fixture.away_team}{" "}
                  </td>
                  <td
                    className="Odds"
                    style={fixture.recent && fixture.recent.away_spread === "True" ? styles.Suggested : styles.Odds}
                  >
                    {" "} {fixture.away_spread} ({fixture.away_spread_price}) {" "}
                  </td>
                  <td
                    className="Odds"
                    style={fixture.recent && fixture.recent.away_ml === "True" ? styles.Suggested : styles.Odds}
                  >
                    {" "}
                    {fixture.away_moneyline}
                  </td>
                  <td
                    className="Odds"
                    style={fixture.recent && fixture.recent.under === "True" ? styles.Suggested : styles.Odds}
                  >
                    {" "}
                    {fixture.under} ({fixture.under_price})
                  </td>
                </tr>
              </tbody>
            </Table>
            {
              
              <Card.Text>
                {isButtonListVisible && (<Card className="cardbutton" style={styles.cardbutton} onClick={togglePowerRatingsVisibility}>
                  <Card.Text>Projections</Card.Text>
                </Card>

                )}
                {isPowerRatingsVisible && isAuthenticated && fixture.insights && (
                  <>
                    <Projections fixture={fixture} onClose={togglePowerRatingsVisibility} />
                  
                  </>
                )}
                <div style={{ marginTop: "1rem" }}> </div>
                {isButtonListVisible && !isDataVisible && (
                  <Card className="cardbutton" style={styles.cardbutton} onClick={toggleDataVisibility}>
                    <Card.Text>Stats</Card.Text>
                  </Card>
                )}
                {isDataVisible && (
                  <div
                    ref={containerRef}
                    style={{
                      overflowY: "scroll",
                      height: "100vh",
                      scrollSnapType: "y mandatory",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <section style={{ ...styles.sectionStyle, minHeight: "110vh" }}>
                        <Wins fixture={fixture} />
                      </section>

                      <section style={styles.sectionStyle}>
                        <Spreads fixture={fixture} />
                      </section>

                      <section style={styles.sectionStyle}>
                        <PointDiff fixture={fixture} />
                      </section>

                      <section style={styles.sectionStyle}>
                        <Totals fixture={fixture} />
                      </section>

                      <section style={styles.sectionStyle}>
                        <Overs fixture={fixture} />
                      </section>

                      <section style={styles.sectionStyle}>
                        {isTutorialMode && (
                          <ul
                            style={{
                              fontSize: "clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)",
                              textAlign: "left",
                              listStyleType: "none",
                              paddingLeft: "0",
                            }}
                          >
                            <li>
                              • You've reached the end of the main stats section. Continue scrolling to see recent
                              games, head-to-head, projections, and comments.
                            </li>
                            <li>
                              • More info:
                              <ul
                                style={{
                                  paddingLeft: "20px",
                                  listStyleType: "none",
                                }}
                              >
                                <li>
                                  ◦ The recent games section shows the last 10 games for each team with the elo ratings
                                  before each game
                                </li>
                                <li>
                                  ◦ The head-to-head section shows up to the last 10 matches between the two current
                                </li>
                                <li>
                                  ◦ The projections section shows our models' recommended plays and win probabilities.
                                  Check this after making your decisions to see how it lines up with our own picks. Or
                                  start with the recommended picks and work backwards. This is how we do it in our
                                  office
                                </li>
                              </ul>
                            </li>
                          </ul>
                        )}
                        <Card className="cardbutton" style={styles.cardbutton} onClick={toggleDataVisibility}>
                          <Card.Text>Close</Card.Text>
                        </Card>
                      </section>
                    </div>
                  </div>
                )}
                <div style={{ marginTop: "1rem" }}>
                {isButtonListVisible &&  (<Card className="cardbutton" style={styles.cardbutton} onClick={toggleRecentsVisibility}>
                    <Card.Text>Recent Games</Card.Text>
                  </Card>)}
                </div>
                <div style={{ marginTop: "1rem" }}>
                  {isRecentsVisible && (
                    <>
                      <Recents name={fixture.home_team} date={fixture.game_time} />
                      <Recents name={fixture.away_team} date={fixture.game_time} />
                      <Card className="cardbutton" style={styles.cardbutton} onClick={toggleRecentsVisibility}>
                          <Card.Text>Close Recent Games</Card.Text>
                        </Card>
                    </>
                  )}
                </div>
                {isButtonListVisible && (<Card className="cardbutton" style={styles.cardbutton} onClick={toggleHeadtoHeadVisibility}>
                  <Card.Text>Head to Head</Card.Text>
                </Card>)}
                <div style={{ marginTop: "1rem" }}>
                  {isHeadtoHeadVisible && (
                    <>
                      <HeadtoHead team1={fixture.home_team} team2={fixture.away_team} game={fixture} onClose={toggleHeadtoHeadVisibility} />
                    </>
                  )}
                </div>
                <div style={{ marginTop: "1rem" }}> </div>
                {isButtonListVisible && <Comments fixture={fixture} isAuthenticated={isAuthenticated} />}
                {isButtonListVisible && <div style={{ marginTop: "1rem" }}> </div>}
                <Card className="cardbutton" style={styles.cardbutton} onClick={handleBackToGames}>
                  <Card.Text>Back</Card.Text>
                </Card>
                <Spacer size={32} />
                <Score fixture={fixture} />
              </Card.Text>
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

const Spacer = ({ size }) => <div style={{ height: size }} />;

export default Games;
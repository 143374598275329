// AppStateContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isTutorialMode, setIsTutorialMode] = useState(() => {
    const savedMode = localStorage.getItem('tutorialMode');
    return savedMode === null ? true : JSON.parse(savedMode);
  });
  const [cache, setCache] = useState({});

  // Probably want to implement some sort of expiry for the auth token
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    setIsAuthenticated(!!token);
  }, []);

  const login = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  const toggleTutorialMode = () => {
    setIsTutorialMode(prev => {
      const newValue = !prev;
      localStorage.setItem('tutorialMode', JSON.stringify(newValue));
      return newValue;
    });
  };

  // Cache functions
  const setWithExpiry = (key, value) => {
    const item = {
      value,
      timestamp: new Date().getTime(),
      expiry: 1 * 60 * 60 * 1000 // 12 hours in milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
    setCache(prev => ({ ...prev, [key]: item }));
  };

  const getWithExpiry = (key) => {
    // First check memory cache
    if (cache[key]) {
      const now = new Date().getTime();
      if (now - cache[key].timestamp <= cache[key].expiry) {
        return cache[key].value;
      }
    }

    // If not in memory, check localStorage
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    
    if (now - item.timestamp > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    
    // Update memory cache
    setCache(prev => ({ ...prev, [key]: item }));
    return item.value;
  };

  const value = {
    isAuthenticated,
    login,
    logout,
    isTutorialMode,
    toggleTutorialMode,
    setWithExpiry,
    getWithExpiry
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider');
  }
  return context;
};
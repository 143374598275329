import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";

function generateAnalysis(fixture) {
  const insights = [];
  
  // Calculate over percentages for different periods
  const home3Pct = (fixture.recent.home_overs_hit_3 / 3) * 100;
  const away3Pct = (fixture.recent.away_overs_hit_3 / 3) * 100;
  const home5Pct = (fixture.recent.home_overs_hit_5 / 5) * 100;
  const away5Pct = (fixture.recent.away_overs_hit_5 / 5) * 100;
  const home10Pct = (fixture.recent.home_overs_hit_10 / 10) * 100;
  const away10Pct = (fixture.recent.away_overs_hit_10 / 10) * 100;
  const homeSeasonPct = (fixture.stats.home_overs_hit / fixture.stats.count_home) * 100;
  const awaySeasonPct = (fixture.stats.away_overs_hit / fixture.stats.count_away) * 100;

  // Last 3 games analysis
  if (Math.abs(home3Pct - 50) >= 10) {
    insights.push(`${fixture.home_team} have gone ${home3Pct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(home3Pct))}% of their last 3 games`);
  }
  if (Math.abs(away3Pct - 50) >= 10) {
    insights.push(`${fixture.away_team} have gone ${away3Pct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(away3Pct))}% of their last 3 games`);
  }

  // Last 5 games analysis
  if (Math.abs(home5Pct - 50) >= 20) {
    insights.push(`${fixture.home_team} have gone ${home5Pct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(home5Pct))}% of their last 5 games`);
  }
  if (Math.abs(away5Pct - 50) >= 20) {
    insights.push(`${fixture.away_team} have gone ${away5Pct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(away5Pct))}% of their last 5 games`);
  }

  // Last 10 games analysis
  if (Math.abs(home10Pct - 50) >= 20) {
    insights.push(`${fixture.home_team} have gone ${home10Pct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(home10Pct))}% of their last 10 games`);
  }
  if (Math.abs(away10Pct - 50) >= 20) {
    insights.push(`${fixture.away_team} have gone ${away10Pct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(away10Pct))}% of their last 10 games`);
  }

  // Season analysis
  if (Math.abs(homeSeasonPct - 50) >= 20) {
    insights.push(`${fixture.home_team} have gone ${homeSeasonPct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(homeSeasonPct))}% of their games this season`);
  }
  if (Math.abs(awaySeasonPct - 50) >= 20) {
    insights.push(`${fixture.away_team} have gone ${awaySeasonPct > 50 ? 'over' : 'under'} in ${Math.round(Math.abs(awaySeasonPct))}% of their games this season`);
  }

  return insights;
}

const Overs = ({ fixture }) => {
  const [showPercentages, setShowPercentages] = useState(true);
  const insights = generateAnalysis(fixture);

  const togglePercentages = () => {
    setShowPercentages(!showPercentages);
  };

  if (!fixture.recent) return null;

  return (
    <>
      <Table bordered className="main">
        <tbody>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePercentages}>Overs</td>
            <td className="Cells" style={styles.Cells}> </td>
            <td className="Cells" style={styles.Cells}> </td>
          </tr>
          <tr>
            <td className="Cells" style={styles.column_1} onClick={togglePercentages}> </td>
            <td className="Cells" style={styles.column_1}>{fixture.home_team}</td>
            <td className="Cells" style={styles.column_1}>{fixture.away_team}</td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePercentages}>Last 3</td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.recent.home_overs_hit_3 / 3) * 100).toFixed(1)}%`
                : `${fixture.recent.home_overs_hit_3} - ${3 - fixture.recent.home_overs_hit_3}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.recent.away_overs_hit_3 / 3) * 100).toFixed(1)}%`
                : `${fixture.recent.away_overs_hit_3} - ${3 - fixture.recent.away_overs_hit_3}`}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePercentages}>Last 5</td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.recent.home_overs_hit_5 / 5) * 100).toFixed(1)}%`
                : `${fixture.recent.home_overs_hit_5} - ${5 - fixture.recent.home_overs_hit_5}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.recent.away_overs_hit_5 / 5) * 100).toFixed(1)}%`
                : `${fixture.recent.away_overs_hit_5} - ${5 - fixture.recent.away_overs_hit_5}`}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePercentages}>Last 10</td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.recent.home_overs_hit_10 / 10) * 100).toFixed(1)}%`
                : `${fixture.recent.home_overs_hit_10} - ${10 - fixture.recent.home_overs_hit_10}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.recent.away_overs_hit_10 / 10) * 100).toFixed(1)}%`
                : `${fixture.recent.away_overs_hit_10} - ${10 - fixture.recent.away_overs_hit_10}`}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePercentages}>Season</td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.stats.home_overs_hit / fixture.stats.count_home) * 100).toFixed(1)}%`
                : `${fixture.stats.home_overs_hit} - ${fixture.stats.count_home - fixture.stats.home_overs_hit}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPercentages
                ? `${((fixture.stats.away_overs_hit / fixture.stats.count_away) * 100).toFixed(1)}%`
                : `${fixture.stats.away_overs_hit} - ${fixture.stats.count_away - fixture.stats.away_overs_hit}`}
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.column_1} onClick={togglePercentages}>Last Season</td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${((fixture.stats.home_overs_hit_last / fixture.stats.count_home_last) * 100).toFixed(1)}%`
                  : `${fixture.stats.home_overs_hit_last} - ${fixture.stats.count_home_last - fixture.stats.home_overs_hit_last}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPercentages
                  ? `${((fixture.stats.away_overs_hit_last / fixture.stats.count_away_last) * 100).toFixed(1)}%`
                  : `${fixture.stats.away_overs_hit_last} - ${fixture.stats.count_away_last - fixture.stats.away_overs_hit_last}`}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div style={{ height: '10vh' }}></div>

      <div style={{ marginTop: '20px' }}>
        {insights.length > 0 && (
          <ul style={{ 
            listStyleType: 'none', 
            padding: 0,
            margin: 0 
          }}>
            {insights.map((insight, i) => (
              <li key={i} style={styles.analysis}>
                • {insight}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

// This function should be moved to a utility file
function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL") {
    return true;
  }
  return fixture.ratings.progress < 0.15;
}

export default Overs;
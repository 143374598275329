import React, { useState } from 'react';
import Table from "react-bootstrap/Table";
import { styles } from "../../styles/styles.js";

function generateAnalysis(fixture) {
  const insights = [];

  // Calculate point differentials
  const home3GameDiff = (fixture.recent.home_PF_3 - fixture.recent.home_PA_3);
  const away3GameDiff = (fixture.recent.away_PF_3 - fixture.recent.away_PA_3);
  const home5GameDiff = (fixture.recent.home_PF_5 - fixture.recent.home_PA_5);
  const away5GameDiff = (fixture.recent.away_PF_5 - fixture.recent.away_PA_5);
  const home10GameDiff = (fixture.recent.home_PF_10 - fixture.recent.home_PA_10);
  const away10GameDiff = (fixture.recent.away_PF_10 - fixture.recent.away_PA_10);
  const homeSeasonDiff = (fixture.stats.home_PF - fixture.stats.home_PA);
  const awaySeasonDiff = (fixture.stats.away_PF - fixture.stats.away_PA);

  // Recent form analysis - last 3 games
  const recent3Diff = home3GameDiff - away3GameDiff;
  if (Math.abs(recent3Diff) > 10) {
    insights.push(`${recent3Diff < 0 ? fixture.away_team : fixture.home_team} has had significantly better point differential in their last 3 games`);
  } else if (Math.abs(recent3Diff) > 5) {
    insights.push(`${recent3Diff < 0 ? fixture.away_team : fixture.home_team} has had better point differential in their last 3 games`);
  }

  // Recent form analysis - last 5 games
  const recent5Diff = home5GameDiff - away5GameDiff;
  if (Math.abs(recent5Diff) > 10) {
    insights.push(`${recent5Diff < 0 ? fixture.away_team : fixture.home_team} has had significantly better point differential in their last 5 games`);
  } else if (Math.abs(recent5Diff) > 5) {
    insights.push(`${recent5Diff < 0 ? fixture.away_team : fixture.home_team} has had better point differential in their last 5 games`);
  }

  // Recent form analysis - last 10 games
  const recent10Diff = home10GameDiff - away10GameDiff;
  if (Math.abs(recent10Diff) > 10) {
    insights.push(`${recent10Diff < 0 ? fixture.away_team : fixture.home_team} has had significantly better point differential in their last 10 games`);
  } else if (Math.abs(recent10Diff) > 5) {
    insights.push(`${recent10Diff < 0 ? fixture.away_team : fixture.home_team} has had better point differential in their last 10 games`);
  }

  // Season analysis
  const seasonDiff = homeSeasonDiff - awaySeasonDiff;
  if (Math.abs(seasonDiff) > 10) {
    insights.push(`${seasonDiff < 0 ? fixture.away_team : fixture.home_team} has had significantly better point differential this season`);
  } else if (Math.abs(seasonDiff) > 5) {
    insights.push(`${seasonDiff < 0 ? fixture.away_team : fixture.home_team} has had better point differential this season`);
  }

  return insights;
}

const PointDiff = ({ fixture }) => {
  const [showPointDifference, setShowPointDifference] = useState(true);

  const togglePointDifference = () => {
    setShowPointDifference(!showPointDifference);
  };

  if (!fixture.recent) return null;

  const insights = generateAnalysis(fixture);

  return (
    <>
      <Table bordered className="main">
        <tbody>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePointDifference}>Point Diff</td>
            <td className="Cells" style={styles.Cells}></td>
            <td className="Cells" style={styles.Cells}></td>
          </tr>
          <tr>
            <td className="Cells" style={styles.column_1} onClick={togglePointDifference}></td>
            <td className="Cells" style={styles.column_1}>{fixture.home_team}</td>
            <td className="Cells" style={styles.column_1}>{fixture.away_team}</td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePointDifference}>Last 3</td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.recent.home_PF_3 - fixture.recent.home_PA_3).toFixed(1)}`
                : `${fixture.recent.home_PF_3} - ${fixture.recent.home_PA_3}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.recent.away_PF_3 - fixture.recent.away_PA_3).toFixed(1)}`
                : `${fixture.recent.away_PF_3} - ${fixture.recent.away_PA_3}`}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePointDifference}>Last 5</td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.recent.home_PF_5 - fixture.recent.home_PA_5).toFixed(1)}`
                : `${fixture.recent.home_PF_5} - ${fixture.recent.home_PA_5}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.recent.away_PF_5 - fixture.recent.away_PA_5).toFixed(1)}`
                : `${fixture.recent.away_PF_5} - ${fixture.recent.away_PA_5}`}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePointDifference}>Last 10</td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.recent.home_PF_10 - fixture.recent.home_PA_10).toFixed(1)}`
                : `${fixture.recent.home_PF_10} - ${fixture.recent.home_PA_10}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.recent.away_PF_10 - fixture.recent.away_PA_10).toFixed(1)}`
                : `${fixture.recent.away_PF_10} - ${fixture.recent.away_PA_10}`}
            </td>
          </tr>
          <tr style={styles.Cells}>
            <td className="Cells" style={styles.column_1} onClick={togglePointDifference}>Season</td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.stats.home_PF - fixture.stats.home_PA).toFixed(1)}`
                : `${fixture.stats.home_PF} - ${fixture.stats.home_PA}`}
            </td>
            <td className="Cells" style={styles.Cells}>
              {showPointDifference
                ? `${(fixture.stats.away_PF - fixture.stats.away_PA).toFixed(1)}`
                : `${fixture.stats.away_PF} - ${fixture.stats.away_PA}`}
            </td>
          </tr>
          {shouldShowLastSeason(fixture) && (
            <tr style={styles.Cells}>
              <td className="Cells" style={styles.column_1} onClick={togglePointDifference}>Last Season</td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(fixture.stats.home_PF_last - fixture.stats.home_PA_last).toFixed(1)}`
                  : `${fixture.stats.home_PF_last} - ${fixture.stats.home_PA_last}`}
              </td>
              <td className="Cells" style={styles.Cells}>
                {showPointDifference
                  ? `${(fixture.stats.away_PF_last - fixture.stats.away_PA_last).toFixed(1)}`
                  : `${fixture.stats.away_PF_last} - ${fixture.stats.away_PA_last}`}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div style={{ height: '10vh' }}></div>

      <div style={{ marginTop: '20px' }}>
        {insights.length > 0 && (
          <ul style={{ 
            listStyleType: 'none', 
            padding: 0,
            margin: 0 
          }}>
            {insights.map((insight, i) => (
              <li key={i} style={styles.analysis}>
                • {insight}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

// This function should be moved to a utility file
function shouldShowLastSeason(fixture) {
  if (fixture.league === "NFL") {
    return fixture.ratings.progress < 0.2;
  }
  return fixture.ratings.progress < 0.15;
}

export default PointDiff;
// const width = window.innerWidth <= 768 ? "55rem" : "80rem";
const width =  "clamp(55rem, 70vw, 100rem)";
const text =  {fontSize: 'clamp(6px, calc(7px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)'};
const odds =
{
  textAlign: "center",
  backgroundColor: "black",
  color: "white",
  fontSize: 'clamp(10px, calc(8px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)',
};

const cells =
{
  textAlign: "center",
  backgroundColor: "black",
  color: "white",
  border: 0,
  fontSize: 'clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)',

};


const column_1 =
{
  textAlign: "center",
  backgroundColor: "black",
  color: "white",
  border: 0,
  fontSize: 'clamp(8px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 20px)',

};

const analysis = {
  fontSize: 'clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)',
  textAlign: "left",  
  paddingLeft: "1rem",
  marginBottom: "2vh"
};


const suggested =
{
  textAlign: "center",
  backgroundColor: "green"
};

const main =
{
  backgroundColor: "black",
  color: "white",
  width: width
}

const cardbutton =
{
  // textAlign: "center", 
  backgroundColor: "black",
  color: "white",
  width: `calc(${width} - 3rem)`,
  borderColor: "white",
  borderWidth: 1,
  borderStyle: "solid",
  padding: 20,
  fontSize: 'clamp(12px, calc(12px + (4) * ((100vw - 300px) / (1200 - 320))), 18px)',
}


const comment =
{
  // textAlign: "center", 
  backgroundColor: "black",
  color: "white",
  width: `calc(${width} - 3rem)`,
  borderColor: "white",
  borderWidth: 1,
  borderStyle: "solid",
  padding: 20
}

const star = {
  width: 'clamp(8px, 4vw, 24px)',
  height: 'auto',
  color: 'white'  // This will set the fill color of the star
}

const commentCard = {
  marginBottom: '10px',
  backgroundColor: 'black',
  color: 'white',
  borderColor: 'white',
  borderWidth: 1,
  borderStyle: 'solid',
  padding: 20,
};

const commentHeader = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
};

const username = {
  fontWeight: 'bold',
  marginRight: '10px',
  color: '#4e79a7', // A blue shade, you can adjust this color
};

const score = {
  color: '#888',
  marginRight: '10px',
};

const timestamp = {
  color: '#888',
  fontSize: '0.8em',
};


const sectionStyle = {
  minHeight: "100vh",
  scrollSnapAlign: "start",
  scrollSnapStop: "always",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "start",
};


export const styles = {
  Odds: odds,
  Suggested: suggested,
  main: main,
  Cells: cells,
  cardbutton: cardbutton,
  comment: comment,
  star: star,
  commentCard: commentCard,
  commentHeader: commentHeader,
  username: username,
  score: score,
  timestamp: timestamp,
  column_1: column_1,
  sectionStyle: sectionStyle,
  text: text,
  analysis: analysis
}
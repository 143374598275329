import React from 'react';
import {convertUTCDateToLocalDate} from '../../utils/localtime.js'
import { styles } from '../../styles/styles.js';


export function Winner({fixture, name, team1, team2}) {
    if ((fixture.home_team === name && fixture.winner === name))
        return (
            <div style={styles.text}>
                <p>
                  {convertUTCDateToLocalDate(fixture.game_time)} <b>{fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)}) {fixture.home_points}</b> vs {fixture.away_points}  {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)})  
                </p>
            </div>
        );
    else if ((fixture.home_team === name && fixture.loser === name))
        return (
            <div style={styles.text}>
                 <p>
                   {convertUTCDateToLocalDate(fixture.game_time)} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)}) {fixture.home_points} vs <b>{fixture.away_points} {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)})</b>  
                </p>
            </div>
        );
    else if ((fixture.away_team === name && fixture.winner === name))
        return (
            <div style={styles.text}>
                 <p> 
                   {convertUTCDateToLocalDate(fixture.game_time)} <b> {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)}) {fixture.away_points} </b> @ {fixture.home_points} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)})  
                </p>
            </div>
        );
    else if ((fixture.away_team === name && fixture.loser === name))
        return (
            <div style={styles.text}>
                 <p>
                  {convertUTCDateToLocalDate(fixture.game_time)} {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)}) {fixture.away_points}  @ <b>{fixture.home_points} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)})</b>  
                </p>
            </div>
        );
    else if ((fixture.away_team === name))
        return (
            <div style={styles.text}>
                 <p>
                   {convertUTCDateToLocalDate(fixture.game_time)} {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)}) {fixture.away_points}  @ {fixture.home_points} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)})  
                </p>
            </div>
        );
    else if ((fixture.home_team === name))
        return (
            <div style={styles.text}>
                 <p>
                   {convertUTCDateToLocalDate(fixture.game_time)} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)})  {fixture.home_points} vs {fixture.away_points}  {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)}) 
                </p>
            </div>
        );
    else if (team1 && team2 && fixture.home_points > fixture.away_points)
        return (
            <div style={styles.text}>
                 <p>
                   {convertUTCDateToLocalDate(fixture.game_time)} <b>{fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)}) {fixture.home_points}</b> vs {fixture.away_points}  {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)})
                </p>
            </div>

        );
    else if (team1 && team2 && fixture.home_points < fixture.away_points)
        return (
            <div style={styles.text}>
                 <p>
                   {convertUTCDateToLocalDate(fixture.game_time)} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)}) {fixture.home_points} vs <b>{fixture.away_points} {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)})</b>
                </p>
            </div>
        )
    else if (team1 && team2 && fixture.home_points === fixture.away_points)
        return (
            <div style={styles.text}>
                 <p>
                   {convertUTCDateToLocalDate(fixture.game_time)} {fixture.home_team} ({fixture.ratings.home_pre_elo.toFixed(0)}) {fixture.home_points} vs {fixture.away_points} {fixture.away_team} ({fixture.ratings.away_pre_elo.toFixed(0)})
                </p>
            </div>
        )
    else
            return (<div style={styles.text}>error  {fixture.home_team} {fixture.home_points} {fixture.away_team} {fixture.away_points} {fixture.game_time} </div>)
}

export default Winner